import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import APIFetch from './services/fetch';

const authentication = () => APIFetch('authenticate');

class RouteGuard extends Component {
  state = {
    component: undefined,
    isLoading: true,
    isError: false,
    fetchedEntitlements: []
  };

  static getDerivedStateFromProps = (newProps, state) => {
    if (newProps.component !== state.component) {
      return newProps;
    }
    return state;
  };

  async componentDidMount() {
    if (this.isLoggedIn()) {
      try {
        const { entitlements = [], loggedOut } = await authentication();
        console.log('ret', loggedOut);

        this.setState({
          fetchedEntitlements: entitlements,
          isLoading: false
        });
      } catch (err) {
        if (err.loggedOut) {
          localStorage.removeItem('bhToken');
          this.setState({ isLoading: false });
        } else {
          this.setState({ isError: err.message, isLoading: false });
        }
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  isLoggedIn = () => {
    return localStorage.getItem('bhToken') || false;
  };

  /**
   * if page has no entitlements then isEntitled;
   * if page has entitlements account must have EVERY entitlement to view
   */
  isEntitled = () =>
    !this.state.entitlements ||
    this.state.entitlements.every(routeEntitlement =>
      this.state.fetchedEntitlements.includes(routeEntitlement)
    );

  render() {
    const {
      component: Component,
      componentProps,
      isLoading,
      isError,
      fetchedEntitlements = [],
      ...rest
    } = this.state;

    if (isLoading) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
          }}>
          <CircularProgress size={100} />
        </div>
      );
    }
    if (isError) {
      return <div>Error: {isError}</div>;
    }
    const ents = fetchedEntitlements;
    return (
      <Route
        key={new Date()}
        {...rest}
        render={props =>
          this.isLoggedIn() ? (
            this.isEntitled() ? (
              <Component
                key={new Date()}
                {...componentProps}
                entitlements={ents}
              />
            ) : (
              <Redirect to={'not-found'} />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

export default RouteGuard;
