import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textField: {
    width: 200
  }
});

function TimePicker(props) {
  return (
    <div className={props.classes.container}>
      <TextField
        id="time"
        label="Report delivery time"
        type="time"
        className={props.classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 3600 // 1 hr
        }}
        variant="outlined"
        {...props}
      />
    </div>
  );
}

export default withStyles(styles)(TimePicker);
