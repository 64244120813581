// Palette
import palette from '../palette';

export default {
  outlined: { textTransform: 'unset' },
  contained: {
    textTransform: 'unset',
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral
    }
  }
};
