import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { AccountSidemenu, ProfileManagement, HotelDetails } from './components';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  }
});

class Account extends Component {
  state = { selectedMenuItem: 'adminProfile' };

  onProfileMenuClick = menuItemName => () => {
    this.setState({ selectedMenuItem: menuItemName });
  };

  renderDetailsPane = () => {
    const { selectedMenuItem } = this.state;

    switch (selectedMenuItem) {
      case 'adminProfile':
        return <ProfileManagement />;
      case 'hotelDetails':
        return <HotelDetails />;
    }
  };

  render() {
    const { classes, entitlements } = this.props;
    const { selectedMenuItem } = this.state;

    return (
      <DashboardLayout title="Manage Account" entitlements={entitlements}>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} xl={4} xs={12}>
              <AccountSidemenu
                onProfileMenuClick={this.onProfileMenuClick}
                selectedMenuItem={selectedMenuItem}
              />
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={12}>
              {this.renderDetailsPane()}
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
