export default theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  info: {},
  locationText: {
    marginTop: theme.spacing(),
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  doneClass: {
    color: '#43a047'
  },
  avatar: {
    marginLeft: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    flexGrow: 0
  },
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  portletFooter: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0
  },
  completeButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 'calc(100% - 20px)'
  },
  centerToolTipText: {
    textAlign: 'center'
  }
});
