export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh'
  },
  grid: {
    height: '100%'
  },
  bulletWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  bullet: {
    backgroundColor: theme.palette.common.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'left',
    // alignItems: 'center', add this to get left bullets in the center always
    backgroundPosition: 'center',
    paddingLeft: theme.spacing(5)
  },
  bulletInner: {
    textAlign: 'left',
    flexBasis: '700px',
    marginRight: theme.spacing(5),
    paddingTop: theme.spacing(5)
  },
  bulletText: {
    margin: `${theme.spacing(2)}px 0px`,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  name: {
    color: '#5E5E5E',
    paddingLeft: 45,
    fontSize: 20,
    lineHeight: '25px',
    paddingBottom: theme.spacing(2)
  },
  bio: {
    color: theme.palette.common.white
  },
  contentWrapper: {},
  content: {
    // height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 0px 15px 2px rgba(0, 0, 0, 0.12)'
    }
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    // paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  backButton: {},
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    height: '100%',
    flexGrow: 1,
    // display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: theme.spacing(2),
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1)
  },
  fields: {
    marginTop: theme.spacing(4)
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2)
    }
  },
  timepicker: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  policy: {
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  policyText: {
    display: 'inline',
    color: theme.palette.text.secondary
  },
  policyUrl: {
    color: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  },
  signUpButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    textTransform: 'unset'
  },
  signIn: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  signInUrl: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginLeft: 12
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    margin: '8px 12px',
    marginBottom: 0
  },
  BHIcon: {
    background: 'url("/images/logos/BHPeople_logo.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: 0,
    // paddingLeft: 10,
    paddingTop: '35%'
  },
  logoContainer: {
    paddingTop: 10,
    paddingLeft: 8,
    // paddingLeft: theme.spacing(4),
    // width: 230,
    paddingRight: 'calc(100% - 276px)',
    // paddingBottom: theme.spacing(2),
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 'calc(100% - 300px)',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(4)
    },
    // width: 300,
    margin: '0 auto',
    marginTop: theme.spacing(4)
  }
});
