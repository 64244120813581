import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import {
  withStyles,
  makeStyles,
  MenuList,
  MenuItem,
  Icon,
  ListItemIcon
} from '@material-ui/core';

// Material components
import {
  Avatar,
  Typography,
  Button,
  LinearProgress,
  Tooltip
} from '@material-ui/core';

// Material icons
import {
  WarningOutlined as DisabledMenuItemIcon,
  Done
} from '@material-ui/icons';

// Shared components
import { Portlet, PortletContent, PortletFooter } from 'components';

// Component styles
import styles from './styles';

const menuOptions = [
  { displayName: 'Admin Profile', value: 'adminProfile', isComplete: true },
  { displayName: 'Hotel Details', value: 'hotelDetails', isComplete: false },
  { displayName: 'Reporting', value: 'reporting', isComplete: false },
  {
    displayName: 'Billing & Payments',
    value: 'billing',
    isDisabled: true
  }
];

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`
      }
    }
  };
}

const useStylesArrow = makeStyles(theme => ({
  tooltip: {
    position: 'relative',
    textAlign: 'center'
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  popper: arrowGenerator(theme.palette.grey[700])
}));

function ArrowTooltip(props) {
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <Tooltip
      classes={classes}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      {...props}
      title={
        <React.Fragment>
          {props.title}
          <span className={arrow} ref={setArrowRef} />
        </React.Fragment>
      }
    />
  );
}

class AccountSidemenu extends Component {
  menuItemIncomplete = menuItemName => (
    <ListItemIcon>
      <DisabledMenuItemIcon />
    </ListItemIcon>
  );

  menuItemComplete = menuItemName => (
    <ListItemIcon>
      <Done classes={{ root: this.props.classes.doneClass }} />
    </ListItemIcon>
  );

  render() {
    const {
      classes,
      className,
      onProfileMenuClick,
      selectedMenuItem,
      ...rest
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    const completePercent = Math.ceil(
      (menuOptions.filter(({ isComplete }) => isComplete).length /
        menuOptions.filter(({ isDisabled }) => !isDisabled).length) *
        100
    );

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant="h2">Dakota Deluxe Glasgow</Typography>
              <Typography variant="subtitle1">Guestline - Reslynx</Typography>
              <div className={classes.progressWrapper}>
                <Typography variant="body1">
                  Account Completeness: {completePercent}%
                </Typography>
                <LinearProgress value={completePercent} variant="determinate" />
              </div>
            </div>
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <MenuList>
            {menuOptions.map(
              ({ displayName, value, isComplete, isDisabled }) => (
                <MenuItem
                  key={value}
                  onClick={onProfileMenuClick(value)}
                  selected={selectedMenuItem === value}
                  // set disabled on an array of disabled tabs, as will be passed from BE
                  disabled={isDisabled}>
                  {isComplete
                    ? this.menuItemComplete(value)
                    : !isDisabled && this.menuItemIncomplete(value)}
                  {displayName}
                </MenuItem>
              )
            )}
          </MenuList>
        </PortletFooter>

        <PortletFooter className={classes.portletFooter}>
          <ArrowTooltip
            title="You must complete all account sections before integration can proceed"
            disableHoverListener={false}>
            <div>
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled
                className={classes.completeButton}>
                Complete integration
              </Button>
            </div>
          </ArrowTooltip>
        </PortletFooter>
      </Portlet>
    );
  }
}

AccountSidemenu.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountSidemenu);
