const requestUrlMap = {
  submitToken: { url: '/authenticate/submitToken', method: 'POST' },
  authenticate: { url: '/authenticate/', method: 'POST' },
  requestToken: { url: '/authenticate/requestToken', method: 'POST' },
  signUp: { url: '/authenticate/signup', method: 'POST' }
};

const baseUrl = 'http://localhost:8050';

const backendRequest = (requestType, data) => {
  const { url } = requestUrlMap[requestType];
  const token = localStorage.getItem('bhToken') || '';

  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    ...(data && { body: JSON.stringify(data) })
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    }
    // error, then reject after .json()
    return response.json().then(message => Promise.reject(message));
  });
};

export default backendRequest;
