import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import Dashboard from './views/Dashboard';
import ProductList from './views/ProductList';
import UserList from './views/UserList';
import Typography from './views/Typography';
import Icons from './views/Icons';
import Account from './views/Account';
import Settings from './views/Settings';
import Authentication from './views/Authentication';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';

import RouteGuard from './RouteGuard';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <RouteGuard
          key={'/dashboard'}
          component={Dashboard}
          exact
          path="/dashboard"
        />
        <RouteGuard
          key={'/users'}
          component={UserList}
          exact
          path="/users"
          entitlements={['developer']}
        />
        <RouteGuard
          key={'/products'}
          component={ProductList}
          exact
          path="/products"
          entitlements={['developer']}
        />
        <RouteGuard
          key={'/typography'}
          component={Typography}
          exact
          path="/typography"
          entitlements={['developer']}
        />
        <RouteGuard
          key={'/icons'}
          component={Icons}
          exact
          path="/icons"
          entitlements={['developer']}
        />
        <RouteGuard
          key={'/account'}
          component={Account}
          exact
          path="/account"
        />
        <RouteGuard
          key={'/settings'}
          component={Settings}
          exact
          path="/settings"
          entitlements={['developer']}
        />
        <Route
          render={() => <Authentication view="signup" />}
          exact
          path="/sign-up"
        />
        <Route
          render={() => <Authentication view="signin-email" />}
          exact
          path="/sign-in"
        />
        <Route component={UnderDevelopment} exact path="/under-development" />
        <Route component={NotFound} exact path="/not-found" />
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}
