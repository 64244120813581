import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Material transition
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

// Shared utilities
import validators from 'common/validators';

// Component styles
import styles from '../../styles';

// Form validation schema
import { signUp as schema } from './schema';

// TODO: consider npm run eject and adding aliases
import APIFetch from '../../../../services/fetch';

validate.validators.checked = validators.checked;

// Service methods
// const signUp = () => {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve(true);
//     }, 1500);
//   });
// };

const signUp = formData => {
  return APIFetch('signUp', formData);
};

class SignUpForm extends Component {
  state = {
    fadeOut: false,
    values: {
      firstName: '',
      lastName: '',
      email: 'a@b.com',
      pms: 'select',
      policy: false
    },
    touched: {
      firstName: false,
      lastName: false,
      email: false,
      pms: false,
      policy: null
    },
    errors: {
      firstName: null,
      lastName: null,
      email: null,
      pms: null,
      policy: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = {
      ...validate(values, schema),
      ...(!validate.contains(['apaleo', 'guestline'], values.pms) && {
        pms: ['Select a valid PMS']
      })
    };

    newState.errors = errors || {};
    newState.isValid = Object.keys(errors).length ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSignUp = async () => {
    try {
      const { history, onSuccessfulSignUp } = this.props;
      const { values } = this.state;

      this.setState({ isLoading: true });

      const { isExistingUser } = await signUp({
        formData: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          pms: values.pms
        }
      });
      console.log('is ex', isExistingUser);

      this.setState({ fadeOut: true });

      onSuccessfulSignUp(values.email, isExistingUser);
      // history.push('/sign-in');
    } catch ({ error }) {
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };

  handleReportTimeChange = e => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        reportTime: e.target.value !== '' ? e.target.value : '07:00'
      }
    }));
    e.persist();
  };

  pmsOptions = [
    { value: 'select', displayName: 'Select PMS' },
    { value: 'apaleo', displayName: 'Apaleo' },
    { value: 'guestline', displayName: 'Guestline Reslynx' }
  ];

  render() {
    const { classes, clearView } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    const showFirstNameError =
      touched.firstName && errors.firstName ? errors.firstName[0] : false;
    const showLastNameError =
      touched.lastName && errors.lastName ? errors.lastName[0] : false;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;
    const showPMSError = touched.pms && errors.pms ? errors.pms[0] : false;
    const showPolicyError =
      touched.policy && errors.policy ? errors.policy[0] : false;

    return (
      <Slide
        direction="left"
        in={!this.state.fadeOut}
        mountOnEnter
        unmountOnExit>
        <div className={classes.contentBody}>
          <form className={classes.form}>
            <Typography className={classes.title} variant="h2">
              Start an account
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              Before we can enrich your guests' profiles, you should set up a
              BHPeople account allowing you to configure our integration with
              your PMS.
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              <b>
                Note: the below details should be for the individual who will
                act as BHPeople account admin.
              </b>
            </Typography>
            <div className={classes.fields}>
              <TextField
                className={classes.textField}
                label="First name"
                name="firstName"
                error={Boolean(showFirstNameError)}
                helperText={showFirstNameError && errors.firstName[0]}
                onChange={event =>
                  this.handleFieldChange('firstName', event.target.value)
                }
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                label="Last name"
                onChange={event =>
                  this.handleFieldChange('lastName', event.target.value)
                }
                error={Boolean(showLastNameError)}
                helperText={showLastNameError && errors.lastName[0]}
                value={values.lastName}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                classes={{ helperText: classes.textFieldHelperText }}
                label="Email address"
                name="email"
                onChange={event =>
                  this.handleFieldChange('email', event.target.value)
                }
                error={Boolean(showEmailError)}
                value={values.email}
                variant="outlined"
                helperText={showEmailError && errors.email[0]}
              />
              <TextField
                className={classes.textField}
                label="PMS"
                error={Boolean(showPMSError)}
                onChange={event =>
                  this.handleFieldChange('pms', event.target.value)
                }
                select
                SelectProps={{ native: true }}
                defaultValue={values.pms}
                helperText={showPMSError && errors.pms[0]}
                variant="outlined">
                {this.pmsOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.displayName}
                  </option>
                ))}
              </TextField>
              <div className={classes.policy}>
                <Checkbox
                  checked={values.policy}
                  className={classes.policyCheckbox}
                  color="primary"
                  name="policy"
                  onChange={() =>
                    this.handleFieldChange('policy', !values.policy)
                  }
                />
                <Typography className={classes.policyText} variant="body1">
                  I have read the{' '}
                  <Link className={classes.policyUrl} to="#">
                    Terms and Conditions
                  </Link>
                  .
                </Typography>
              </div>
              {showPolicyError && (
                <Typography className={classes.fieldError} variant="body2">
                  {errors.policy[0]}
                </Typography>
              )}
            </div>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {submitError}
              </Typography>
            )}
            <Button
              className={classes.signUpButton}
              color="primary"
              disabled={!isValid || isLoading}
              onClick={this.handleSignUp}
              size="large"
              variant="contained">
              {isLoading ? <CircularProgress size={26} /> : 'Join'}
            </Button>
            <Typography className={classes.signIn} variant="body1">
              Already have a <b>BHPeople</b> account?{' '}
              <Link
                className={classes.signInUrl}
                onClick={clearView}
                to={{
                  pathname: '/sign-in'
                }}>
                Sign In
              </Link>
            </Typography>
          </form>
        </div>
      </Slide>
    );
  }
}

SignUpForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSuccessfulSignUp: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(SignUpForm);
