import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

import { Router, Redirect } from 'react-router';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';

// Material transition
import Slide from '@material-ui/core/Slide';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

import { InfoPane, SignUpForm, SignInForm } from './components';

class Authentication extends Component {
  state = {
    view: '',
    email: undefined
  };

  static getDerivedStateFromProps(nprops, nstate) {
    if (!nstate.view && nprops.view) {
      // first mount, take the props view to start auth process
      return { ...nstate, ...nprops };
    }
    // if (nprops.postAuthComponent) {
    //   return { ...nstate, postAuthComponent: nprops.postAuthComponent };
    // }
    // only need this if not using clearView method
    // if (
    //   (nprops.view.includes('signup') &&
    //     nstate.view.includes('signin-email')) ||
    //   (nstate.view.includes('signup') && nprops.view.includes('signin-email'))
    // ) {
    //   // swapping paths (from sign up to sign in or vice versa)
    //   return { ...nstate, view: nprops.view };
    // }
    return nstate;
  }

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  onSuccessfulSignUp = (email, isExistingUser) => {
    this.setState({ view: 'signin-token', email, isExistingUser });
  };

  onSuccessfulEmailSend = email => {
    this.setState({ view: 'signin-token', email });
  };

  onSuccessfulSignIn = () => {
    // need this to re-render
    this.setState({ authed: true });
    this.context.router.push(); // needed to overcome React Router Link needing to be a ForwardRef
  };

  clearView = () => this.setState({ view: '' });

  render() {
    const { classes, location = {} } = this.props;
    const { email, isExistingUser, view, authed } = this.state;

    return localStorage.getItem('bhToken') || authed ? (
      <Redirect
        to={(location.state && location.state.from) || { pathname: '/' }}
      />
    ) : (
      <div className={classes.root} key={view}>
        <Grid className={classes.grid} container>
          <Grid className={classes.bulletWrapper} item md={7}>
            <InfoPane />
          </Grid>
          <Grid className={classes.content} item md={5} xs={12}>
            <div className={classes.contentHeader}>
              <IconButton
                className={classes.backButton}
                onClick={this.handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <div className={classes.logoContainer}>
                <div className={classes.BHIcon} />
              </div>
            </div>
            {view === 'signup' && (
              <SignUpForm
                history={this.props.history}
                onSuccessfulSignUp={this.onSuccessfulSignUp}
                clearView={this.clearView}
              />
            )}
            {view.includes('signin') && (
              <SignInForm
                key={view}
                tokenView={view.includes('token')}
                email={email}
                isExistingUser={isExistingUser}
                onSuccessfulSignIn={this.onSuccessfulSignIn}
                onSuccessfulEmailSend={this.onSuccessfulEmailSend}
                clearView={this.clearView}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Authentication.defaultProps = {
  view: 'signup'
};

Authentication.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  view: PropTypes.string
};

export default compose(
  withRouter,
  withStyles(styles)
)(Authentication);
