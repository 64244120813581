export const signUp = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  pms: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

export const signIn = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: { message: 'must be a valid email address' },
    length: {
      maximum: 64
    }
  },
  token: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 19,
      minimum: 19,
      message: 'must be 16 characters' // 16 excluding '-'
    }
  }
};
