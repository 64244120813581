import React from 'react';

// Material helpers
import { withStyles, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';

// Component styles
import styles from '../../styles';

const InfoPane = ({ classes }) => {
  return (
    <div className={classes.bullet}>
      <div className={classes.bulletInner}>
        <Typography className={classes.bulletText} variant="h1">
          <li>integrate</li>
        </Typography>
        <Typography className={classes.name} variant="body1">
          <b>Complete the BHPeople integration with your PMS.</b>
          <br />
          We never update any of your hotel details, other than the enhanced
          booking comments.
        </Typography>
        <Typography className={classes.bulletText} variant="h1">
          <li>automate</li>
        </Typography>
        <Typography className={classes.name} variant="body1">
          <b>BHPeople works in the background.</b>
          <br />
          All daily, new reservations will undergo BHPeople advance deep web
          analysis to establish further guest personal details. The results will
          automatically populate corresponding guest profiles in your PMS and be
          delivered through a daily email report.
        </Typography>
        <Typography className={classes.bulletText} variant="h1">
          <li>captivate</li>
        </Typography>
        <Typography className={classes.name} variant="body1">
          <b>Create truly unique and memorable experiences.</b>
          <br />
          BHPeople provides enhanced guest profile information, aiding you in
          offering a bespoke, personal service to enhance guests’ stays.
        </Typography>
      </div>
    </div>
  );
};

InfoPane.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InfoPane);
