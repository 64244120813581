import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
  withStyles,
  Button,
  Paper,
  Popper,
  Typography,
  Fade,
  Menu,
  MenuItem,
  MenuList,
  ListItemText,
  ListItem,
  ClickAwayListener
} from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  Budget,
  Users,
  Progress,
  SalesChart,
  DevicesChart
} from './components';

// Material icons
import { ExpandMore, CalendarToday } from '@material-ui/icons';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  item: {
    height: '100%'
  },
  timeRoot: {
    position: 'relative',

    right: 0,
    zIndex: 1000,
    width: theme.spacing(16)
  },
  timeButton: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    marginTop: 0,
    minWidth: 150
  },
  timeButtonText: {
    justifyContent: 'left',
    display: 'flex'
  },
  downArrowRoot: {
    marginLeft: 'auto'
  },
  clockIconRoot: {
    marginRight: theme.spacing()
  },
  paper: {
    width: 150,
    padding: 0
    // marginLeft: theme.spacing()
  },
  menuItem: {
    minHeight: theme.spacing(4),
    paddingLeft: theme.spacing()
  }
});

const timeOptions = [
  { displayName: 'Today', value: 'today' },
  { displayName: 'Past week', value: 'week' },
  { displayName: 'Past month', value: 'month' }
];

class Dashboard extends Component {
  state = {
    anchorEl: null,
    activeTime: 'week'
  };

  handleTimeButtonClick = e => {
    const anchorEl = this.state.anchorEl ? null : e.currentTarget;
    this.setState({
      anchorEl
    });
    e.persist();
  };

  handleTimeSelect = value => () =>
    setTimeout(() => this.setState({ activeTime: value, anchorEl: null }), 300);

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const { classes, entitlements } = this.props;
    const { activeTime: activeTimeValue } = this.state;
    const id = this.state.anchorEl ? 'simple-popper' : undefined;
    const activeTime = timeOptions.find(
      ({ value }) => value === activeTimeValue
    );

    return (
      <DashboardLayout title="Overview Dashboard" entitlements={entitlements}>
        <div className={classes.root}>
          <Button
            aria-describedby={id}
            variant="outlined"
            classes={{ label: classes.timeButtonText }}
            className={classes.timeButton}
            onClick={this.handleTimeButtonClick}>
            <CalendarToday classes={{ root: classes.clockIconRoot }} />
            {activeTime.displayName}
            <ExpandMore classes={{ root: classes.downArrowRoot }} />
          </Button>
          <Popper
            id={id}
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={500}>
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList classes={{ root: classes.paper }}>
                      {timeOptions.map(
                        ({ displayName, value }) =>
                          value !== activeTimeValue && (
                            <MenuItem
                              classes={{ root: classes.menuItem }}
                              onClick={this.handleTimeSelect(value)}>
                              {displayName}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>

          {/* <div className={classes.timeRoot}>
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <div>
                <Button
                  className={classes.timeButton}
                  size="small"
                  variant="outlined"
                  onClick={this.handleClick}>
                  Open menu
                </Button>
                {this.state.showTimeSelect && (
                  <Paper className={classes.paper}>
                    <ListItem button>
                      <ListItemText primary="Today" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Past week" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Past month" />
                    </ListItem>
                  </Paper>
                )}
              </div>
            </ClickAwayListener>
          </div> */}
          {/* <Grid container spacing={4}>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <Budget className={classes.item} />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <Users className={classes.item} />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <Progress className={classes.item} />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <SalesChart className={classes.item} />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <DevicesChart className={classes.item} />
            </Grid>
            {/* <Grid item lg={4} md={6} xl={3} xs={12}>
              <ProductList className={classes.item} />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <OrdersTable className={classes.item} />
            </Grid>
          </Grid> */}

          {/* Below will position 3 on left with pie on right for all except xs */}
          <Grid container spacing={4}>
            <Grid item container spacing={4} lg={6} xl={6} sm={6}>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Budget className={classes.item} />
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Users className={classes.item} />
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Progress className={classes.item} />
              </Grid>
            </Grid>
            <Grid item spacing={4} lg={6} xl={6} sm={6} xs={12}>
              <Grid item lg={12} md={12} xl={12} xs={12} sm={12}>
                <DevicesChart className={classes.item} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
