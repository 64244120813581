import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Material transition
import Slide from '@material-ui/core/Slide';

// Shared utilities
import validators from 'common/validators';

// Component styles
import styles from '../../styles';

// Form validation schema
import { signIn as schema } from '../SignUpForm/schema';

import APIFetch from '../../../../services/fetch';

validate.validators.checked = validators.checked;

// Service methods
const sendEmail = data => APIFetch('requestToken', data);
const submitToken = data => APIFetch('submitToken', data);

const formMapper = {
  token: {
    subtitle: (email, isExistingUser) => (
      <>
        {isExistingUser ? (
          <b>
            Email <i>{email}</i> already has an existing BHPeople account. A new
            token has been sent to this email.
          </b>
        ) : (
          <>
            Check <b>{email}</b> - we just sent you a unique session code to
            enter below and access your BHPeople account.
          </>
        )}
      </>
    ),
    label: 'Token',
    name: 'token',
    buttonText: 'Sign in',
    helpText: "Didn't receive a token?",
    helpLink: 'Send another'
  },
  email: {
    subtitle: () => (
      <>
        Enter the email address you signed up to BHPeople with. We'll email you
        a unique login token.
      </>
    ),
    label: 'Email address',
    name: 'email',
    buttonText: 'Send log in token',
    helpText: (
      <>
        Don't have a <b>BHPeople</b> account?
      </>
    ),
    helpLink: 'Sign up'
  }
};

class SignInForm extends Component {
  state = {
    values: {
      token: '',
      email: ''
    },
    touched: {
      token: false,
      email: false
    },
    errors: {
      token: null,
      email: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };

  validateForm = formType =>
    _.debounce(() => {
      const { values } = this.state;

      const newState = { ...this.state };
      const errors = validate(values, { [formType]: schema[formType] });

      newState.errors = errors || {};
      newState.isValid = errors ? false : true;

      this.setState(newState);
    }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] =
      field === 'token'
        ? this.formatTokenValue(newState.values[field], value)
        : value;

    this.setState(newState, this.validateForm(field));
  };

  formatTokenValue = (existingToken, newToken) => {
    const charLength = newToken.replace(/-/g, '').length; // remove '-'

    return `${
      charLength === 17 || newToken.slice(newToken.length - 1) === ' ' // max token length and no spaces allowed
        ? existingToken
        : newToken
    }${
      charLength % 4 === 0 && // each word is 4 chars long
      charLength < 13 && // only '-' separate first 3 words
      newToken.length > existingToken.length && // only apply '-' when adding chars not deleting
      newToken.slice(newToken.length - 1) !== '-' && // dont add '-' when already been added by user
      newToken.slice(newToken.length - 1) !== ' ' // dont add '-' when newToken has a space - will be using old token for val
        ? '-'
        : ''
    }`;
  };

  handleSubmit = formType => async () => {
    try {
      const { history, onSuccessfulEmailSend } = this.props;
      const { values, errors } = this.state;

      if (formType === 'token') {
        return await sendEmail({
          email: this.props.email
        });
      }

      this.setState(prevState => ({
        isLoading: true,
        touched: { ...prevState.touched, [formType]: true }
      }));
      this.validateForm(formType);
      if (!errors[formType]) {
        await sendEmail({
          email: values.email
        });

        onSuccessfulEmailSend(values.email);
        // history.push('/sign-in');
      }
    } catch ({ error }) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  handleTokenSend = async () => {
    try {
      const { history, onSuccessfulSignIn } = this.props;
      const { values } = this.state;

      this.setState({ isLoading: true });

      // email and token from the form
      const { error, token } = await submitToken({
        email: this.props.email,
        loginToken: values.token
      });

      // successful login? then save that token in localStorage
      localStorage.setItem('bhToken', token);

      onSuccessfulSignIn();
      history.push('/sign-in');
    } catch ({ error }) {
      this.setState({
        isLoading: false,
        submitError: error
      });
    }
  };

  renderForm = formType => {
    const {
      errors,
      values,
      touched,
      isValid,
      submitError,
      isLoading
    } = this.state;
    const { classes } = this.props;
    const {
      subtitle,
      label,
      name,
      buttonText,
      helpText,
      helpLink
    } = formMapper[formType];
    const isTokenForm = formType === 'token';
    const showTokenError =
      touched.token && errors.token ? errors.token[0] : false;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;

    return (
      <form className={classes.form}>
        <Typography className={classes.title} variant="h2">
          Log in
        </Typography>
        <Typography className={classes.subtitle} variant="body1">
          {subtitle(this.props.email || '', this.props.isExistingUser)}
        </Typography>
        <div className={classes.fields}>
          <TextField
            className={classes.textField}
            label={label}
            name={name}
            autoFocus
            error={Boolean(showTokenError || showEmailError)}
            helperText={
              (showEmailError || showTokenError) && errors[formType][0]
            }
            onChange={event => this.handleFieldChange(name, event.target.value)}
            value={values[formType]}
            variant="outlined"
          />
        </div>
        <Button
          className={classes.signUpButton}
          color="primary"
          disabled={!isValid || isLoading}
          onClick={
            isTokenForm ? this.handleTokenSend : this.handleSubmit(formType)
          }
          size="large"
          variant="contained">
          {isLoading ? <CircularProgress size={26} /> : buttonText}
        </Button>
        {submitError && (
          <Typography className={classes.submitError} variant="body2">
            {submitError}
          </Typography>
        )}
        <Typography className={classes.signIn} variant="body1">
          {helpText}{' '}
          {isTokenForm ? (
            <span
              className={classes.signInUrl}
              onClick={this.handleSubmit(formType)}>
              {helpLink}
            </span>
          ) : (
            <Link
              className={classes.signInUrl}
              onClick={this.props.clearView}
              to={{
                pathname: '/sign-up'
              }}>
              {helpLink}
            </Link>
          )}
        </Typography>
      </form>
    );
  };

  render() {
    const { classes, tokenView } = this.props;

    return (
      <Slide direction="left" in mountOnEnter unmountOnExit>
        <div className={classes.contentBody}>
          {this.renderForm(tokenView ? 'token' : 'email')}
        </div>
      </Slide>
    );
  }
}

SignInForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSuccessfulSignUp: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(SignInForm);
